import { useEffect, useState, FormEvent } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import useApi from '../../../api';
import { trimEditedInput, getErrorMessage } from '../../../helper';
import { CAPAYear } from '../../../models/CAPAYear';

type CAPAYearEditFormProps = {
  capaYear: CAPAYear;
  editMode: boolean;
  onClose(): void;
};

function CAPAYearEditForm(props: CAPAYearEditFormProps) {
  const { capaYear, editMode, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [jiraInitiative, setJiraInitiative] = useState<string>('');
  const [year, setYear] = useState<number>(2000);

  useEffect(() => {
    setJiraInitiative(capaYear.jiraInitiative);
    setYear(capaYear.year);
  }, [capaYear]);

  const mutation = useMutation(
    (input: CAPAYear) => {
      // Validate and clean up input fields
      const jiraInitiative = trimEditedInput(input.jiraInitiative);
      setJiraInitiative(jiraInitiative);

      return api.updateCAPAYear(input.id, jiraInitiative, year);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['capaYear', capaYear.id]);
        onClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.mutate({
      ...capaYear,
      jiraInitiative,
      year,
    });
  };

  const onCancel = () => {
    setJiraInitiative(capaYear.jiraInitiative);
    setYear(capaYear.year);
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={editMode} onHide={onCancel} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>CAPA Based on Year</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Somethings not right...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Parent Link</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={jiraInitiative}
              onChange={(e) => setJiraInitiative(e.target.value)}
            />
            <Form.Label>Year</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={year}
              onChange={(e) => setYear(Number(e.target.value))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{ marginRight: '0.1rem' }}
          >
            Update
          </Button>
          <Button color="error" variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function CAPAYearDetailPage() {
  const api = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const params = useParams();
  const capaYearId = parseInt(params.capaYearId ?? '', 10);

  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    data: capaYearDetailPage,
    error,
    isLoading,
    isSuccess,
  } = useQuery(['capaYear', capaYearId], async () => {
    const capaYear = await api.getCAPAYear(capaYearId);

    return { capaYear };
  });

  const deleteMutation = useMutation<void, Error, number>((id) => api.deleteCAPAYear(id), {
    onSuccess: () => {
      // TODO Update this to match the query for the list page
      queryClient.invalidateQueries(['capaYears']);
      navigate('/capaYears');
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <Paper
        sx={{
          p: 2,
          margin: 'auto',
          marginTop: '1rem',
          maxWidth: 500,
          flexGrow: 1,
          borderRadius: '0.3rem',
          background:
            'linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container spacing={0.5}>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                  variant="subtitle1"
                  component="h5"
                >
                  Year: {capaYearDetailPage.capaYear.year}
                </Typography>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                  variant="subtitle1"
                  component="h5"
                >
                  Parent link: {capaYearDetailPage.capaYear.jiraInitiative}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton aria-label="edit" color="primary" onClick={() => setEditMode(true)}>
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => deleteMutation.mutate(capaYearDetailPage.capaYear.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <CAPAYearEditForm
          capaYear={capaYearDetailPage.capaYear}
          editMode={editMode}
          onClose={() => setEditMode(false)}
        />
      </Paper>
    );
  }

  if (error instanceof Error) {
    return <div>{error.message}</div>;
  }
  if (deleteMutation.isError) {
    return <div>Failed to delete a CAPA based on year: {deleteMutation.error.message}</div>;
  }
  return <div>Error occurred</div>;
}
