import { InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios';

import { config } from './config';
import { AssignmentGroup } from './models/AssignmentGroup';
import { Channel } from './models/Channel';
import { Constants } from './models/Constants';
import { PaginationInfo } from './models/PaginationInfo';
import { Platform } from './models/Platform';
import { YammerPost } from './models/YammerPost';
import { OutageNotification } from './models/OutageNotification';
import { YammerGroup } from './models/YammerGroup';
import { CAPAYear } from './models/CAPAYear';
import { CAPA } from './models/CAPA';
import { YammerCommunityMemberBackups } from './models/YammerCommunityMemberBackups';
import { YammerCommunityMemberBackupUsers } from './models/YammerCommunityMemberBackupUsers';

type ApiResponse<T> = {
  status: string;
  message?: string;
  data: T;
};

type PlatformPage = {
  platforms: Platform[];
  pagination: PaginationInfo;
};

type ChannelPage = {
  channels: Channel[];
  pagination: PaginationInfo;
};

type AssignmentGroupPage = {
  assignmentGroups: AssignmentGroup[];
  pagination: PaginationInfo;
};

type OutageNotificationPage = {
  outageNotifications: OutageNotification[];
};

type YammerGroupPage = {
  yammerGroups: YammerGroup[];
  pagination: PaginationInfo;
};

type YammerCommunityMemberBackupUsersPage = {
  yammerCommunityMemberBackupUsers: YammerCommunityMemberBackupUsers[];
};

type CAPAYearPage = {
  capaYears: CAPAYear[];
  pagination: PaginationInfo;
};

type CAPAPage = {
  capas: CAPA[];
  pagination: PaginationInfo;
};

class ApiClient {
  private readonly msalInstance?: IPublicClientApplication;
  private readonly axios: AxiosInstance;

  constructor(msalInstance?: IPublicClientApplication) {
    this.msalInstance = msalInstance;
    this.axios = axios.create({
      baseURL: config.api.baseUrl,
    });
  }

  private async getToken(): Promise<string> {
    if (!this.msalInstance) {
      throw new Error('No Microsoft authentication is not configured!');
    }

    // NOTE: MSAL caches tokens internally
    const account = this.msalInstance.getActiveAccount();
    if (account) {
      const request = {
        scopes: config.azureAd.scopes,
        account,
      };
      try {
        const response = await this.msalInstance.acquireTokenSilent(request);
        return response.idToken;
      } catch (err) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (err instanceof InteractionRequiredAuthError) {
          const response = await this.msalInstance.acquireTokenPopup(request);
          return response.idToken;
        } else {
          throw err;
        }
      }
    }
    throw new Error('No active Azure AD account');
  }

  private async makeRequest<T>(method: Method, endpoint: string, config?: AxiosRequestConfig) {
    const token = await this.getToken();
    const requestConfig: AxiosRequestConfig = {
      method,
      url: endpoint,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (config) {
      requestConfig.params = config.params;
      requestConfig.data = config.data;
    }

    try {
      const res = await this.axios.request<ApiResponse<T>>(requestConfig);
      return res.data.data;
    } catch (err) {
      if (err.response) {
        const message = err.response.data.message || 'Internal error';
        const apiError = new Error(`[API][${method} ${endpoint}]${message}`);
        Object.assign(apiError, { httpStatus: err.response.status });
        throw apiError;
      } else if (err.request) {
        throw new Error(`[API][${method} ${endpoint}][TIMEOUT] ${err.message}`);
      } else {
        throw new Error(`[API][${method} ${endpoint}][FAILED_REQUEST] ${err.message}`);
      }
    }
  }

  async createPlatform(name: string, assignmentGroupId: number): Promise<Platform> {
    const response = await this.makeRequest<{ platform: Platform }>('POST', '/api/platforms', {
      data: {
        name,
        assignmentGroupId,
      },
    });
    return response.platform;
  }

  async getPlatforms(cursor?: number): Promise<PlatformPage> {
    return this.makeRequest<PlatformPage>('GET', `/api/platforms`, {
      params: {
        pageSize: Constants.numberOfCardsDisplayed,
        cursor,
      },
    });
  }

  async getPlatform(id: number): Promise<Platform> {
    const response = await this.makeRequest<{ platform: Platform }>('GET', `/api/platforms/${id}`);
    return response.platform;
  }

  async updatePlatform(id: number, name: string, assignmentGroupId: number): Promise<Platform> {
    const response = await this.makeRequest<{ platform: Platform }>('PUT', `/api/platforms/${id}`, {
      data: {
        name,
        assignmentGroupId,
      },
    });
    return response.platform;
  }

  async deletePlatform(id: number) {
    await this.makeRequest<{ platform: Platform }>('DELETE', `/api/platforms/${id}`);
  }

  async getAssignmentGroups(cursor?: number): Promise<AssignmentGroupPage> {
    return this.makeRequest<AssignmentGroupPage>('GET', `/api/assignmentGroups`, {
      params: {
        pageSize: Constants.numberOfCardsDisplayed,
        cursor,
      },
    });
  }

  async createAssignmentGroup(name: string): Promise<AssignmentGroup> {
    const response = await this.makeRequest<{ assignmentGroup: AssignmentGroup }>(
      'POST',
      '/api/assignmentGroups',
      {
        data: {
          name,
        },
      }
    );
    return response.assignmentGroup;
  }

  async getAssignmentGroup(id: number): Promise<AssignmentGroup> {
    const response = await this.makeRequest<{ assignmentGroup: AssignmentGroup }>(
      'GET',
      `/api/assignmentGroups/${id}`
    );
    return response.assignmentGroup;
  }

  async updateAssignmentGroup(id: number, name: string): Promise<AssignmentGroup> {
    const response = await this.makeRequest<{ assignmentGroup: AssignmentGroup }>(
      'PUT',
      `/api/assignmentGroups/${id}`,
      {
        data: {
          name,
        },
      }
    );
    return response.assignmentGroup;
  }

  async deleteAssignmentGroup(id: number) {
    await this.makeRequest<{ channel: AssignmentGroup }>('DELETE', `/api/assignmentGroups/${id}`);
  }

  async getChannels(cursor?: number): Promise<ChannelPage> {
    return this.makeRequest<ChannelPage>('GET', `/api/channels`, {
      params: {
        pageSize: Constants.numberOfCardsDisplayed,
        cursor,
      },
    });
  }

  async createChannel(name: string, assignmentGroupId: number): Promise<Channel> {
    const response = await this.makeRequest<{ channel: Channel }>('POST', '/api/channels', {
      data: {
        name,
        assignmentGroupId,
      },
    });
    return response.channel;
  }

  async getChannel(id: number): Promise<Channel> {
    const response = await this.makeRequest<{ channel: Channel }>('GET', `/api/channels/${id}`);
    return response.channel;
  }

  async updateChannel(id: number, assignmentGroupId: number): Promise<Channel> {
    const response = await this.makeRequest<{ channel: Channel }>('PUT', `/api/channels/${id}`, {
      data: {
        assignmentGroupId,
      },
    });
    return response.channel;
  }

  async deleteChannel(id: number) {
    await this.makeRequest<{ channel: Channel }>('DELETE', `/api/channels/${id}`);
  }

  async createYammerGroup(name: string): Promise<YammerGroup> {
    const response = await this.makeRequest<{ yammerGroup: YammerGroup }>(
      'POST',
      '/api/yammer_group',
      {
        data: {
          name,
        },
      }
    );
    return response.yammerGroup;
  }

  async getYammerGroups(cursor?: number): Promise<YammerGroupPage> {
    return this.makeRequest<YammerGroupPage>('GET', `/api/yammer_group`, {
      params: {
        pageSize: Constants.numberOfCardsDisplayed,
        cursor,
      },
    });
  }

  async getYammerCommunityMembersBackup(
    yammerCommunityId: number
  ): Promise<YammerCommunityMemberBackups> {
    const response = await this.makeRequest<{
      yammerCommunityMemberBackups: YammerCommunityMemberBackups;
    }>('GET', `/api/yammer_community_member_backups/${yammerCommunityId}`);
    return response.yammerCommunityMemberBackups;
  }

  async getYammerCommunityMembersBackupUsers(
    backupId: number
  ): Promise<YammerCommunityMemberBackupUsersPage> {
    return this.makeRequest<YammerCommunityMemberBackupUsersPage>(
      'GET',
      `/api/yammer_community_member_backup_users/${backupId}`
    );
  }

  async getOutageNotification(id: number): Promise<OutageNotification> {
    const response = await this.makeRequest<{ outageNotification: OutageNotification }>(
      'GET',
      `/api/outage_notification/${id}`
    );
    return response.outageNotification;
  }

  async getOutageNotifications(): Promise<OutageNotificationPage> {
    return this.makeRequest<OutageNotificationPage>('GET', `/api/outage_notification`);
  }

  async updateOutageNotification(id: number, yammerPost: YammerPost): Promise<OutageNotification> {
    const response = await this.makeRequest<{ outageNotification: OutageNotification }>(
      'PUT',
      `/api/outage_notification/${id}`,
      {
        data: yammerPost,
      }
    );
    return response.outageNotification;
  }

  async postOutageNotification(yammerPost: YammerPost): Promise<number> {
    const response = await this.makeRequest<OutageNotification>(
      'POST',
      `/api/outage_notification`,
      {
        data: yammerPost,
      }
    );
    return response.messageId;
  }

  async getCAPAYears(cursor?: number): Promise<CAPAYearPage> {
    return this.makeRequest<CAPAYearPage>('GET', `/api/capaYears`, {
      params: {
        pageSize: Constants.numberOfCardsDisplayed,
        cursor,
      },
    });
  }

  async getCAPAYear(id: number): Promise<CAPAYear> {
    const response = await this.makeRequest<{ capaYear: CAPAYear }>('GET', `/api/capaYears/${id}`);
    return response.capaYear;
  }

  async createCAPAYear(jiraInitiative: string, year: number): Promise<CAPAYear> {
    const response = await this.makeRequest<{ capaYear: CAPAYear }>('POST', '/api/capaYears', {
      data: {
        jiraInitiative,
        year,
      },
    });
    return response.capaYear;
  }

  async updateCAPAYear(id: number, jiraInitiative: string, year: number): Promise<CAPAYear> {
    const response = await this.makeRequest<{ capaYear: CAPAYear }>('PUT', `/api/capaYears/${id}`, {
      data: {
        jiraInitiative,
        year,
      },
    });
    return response.capaYear;
  }

  async deleteCAPAYear(id: number) {
    await this.makeRequest<{ capaYear: CAPAYear }>('DELETE', `/api/capaYears/${id}`);
  }

  async getCAPAs(cursor?: number): Promise<CAPAPage> {
    return this.makeRequest<CAPAPage>('GET', `/api/capas`, {
      params: {
        pageSize: Constants.numberOfCardsDisplayed,
        cursor,
      },
    });
  }

  async getCAPA(id: number): Promise<CAPA> {
    const response = await this.makeRequest<{ capa: CAPA }>('GET', `/api/capas/${id}`);
    return response.capa;
  }

  async createCAPA(snowCAPAId: string, jiraEpicId: string, capaYearId: number): Promise<CAPA> {
    const response = await this.makeRequest<{ capa: CAPA }>('POST', '/api/capas', {
      data: {
        snowCAPAId,
        jiraEpicId,
        capaYearId,
      },
    });
    return response.capa;
  }

  async updateCAPA(
    id: number,
    snowCAPAId: string,
    jiraEpicId: string,
    capaYearId: number
  ): Promise<CAPA> {
    const response = await this.makeRequest<{ capa: CAPA }>('PUT', `/api/capas/${id}`, {
      data: {
        snowCAPAId,
        jiraEpicId,
        capaYearId,
      },
    });
    return response.capa;
  }

  async deleteCAPA(id: number) {
    await this.makeRequest<{ capa: CAPA }>('DELETE', `/api/capas/${id}`);
  }
}

let apiClient: ApiClient | undefined;

export default function useApi(): ApiClient {
  // TODO There has to be a better way
  // const [api, setApi] = useState<ApiClient>(new ApiClient());
  // const { instance } = useMsal();

  // useEffect(() => {
  //   setApi(new ApiClient(instance));
  // }, [instance]);

  // return api;

  const { instance } = useMsal();
  if (!apiClient) {
    apiClient = new ApiClient(instance);
  }
  return apiClient;
}
