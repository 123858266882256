import { Pagination } from 'react-bootstrap';
import { PaginationInfo } from '../../models/PaginationInfo';

interface PaginationProps {
  pagination: PaginationInfo;
  onPageChange(newCursor: number): void;
}

export default function CardsPagination(props: PaginationProps) {
  const { pagination, onPageChange } = props;

  const prevButtonClickHandler = () => {
    if (pagination.previous !== null) {
      onPageChange(pagination.previous);
    }
  };

  const nextButtonClickHandler = () => {
    if (pagination.next !== null) {
      onPageChange(pagination.next);
    }
  };

  return (
    <div>
      <Pagination
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '0.2rem',
        }}
      >
        <Pagination.Prev disabled={pagination.previous === null} onClick={prevButtonClickHandler}>
          Previous
        </Pagination.Prev>
        <Pagination.Next disabled={pagination.next === null} onClick={nextButtonClickHandler}>
          Next
        </Pagination.Next>
      </Pagination>
    </div>
  );
}
