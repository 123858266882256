import 'bootstrap/dist/css/bootstrap.min.css';
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';
import { config } from './config';
import App from './components/App';
import './components/App.scss';
import reportWebVitals from './reportWebVitals';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: config.azureAd.clientId,
    authority: `https://login.microsoftonline.com/${config.azureAd.tenantId}`,
    redirectUri: '/auth/callback',
  },
});

// TODO Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const container = document.getElementById('root');
if (!container) {
  throw new Error('HTML element with ID root is missing! No where for React to mount to.');
}
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App msalInstance={msalInstance} />
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
