import { FormEvent, useEffect, useState } from 'react';
import { Modal, Form, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import useApi from '../../../api';
import AssignmentGroupDropdown from '../../../components/AssignmentGroupDropdown/AssignmentGroupDropdown';
import { getErrorMessage } from '../../../helper';
import { AssignmentGroup } from '../../../models/AssignmentGroup';
import { Channel } from '../../../models/Channel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

type ChannelEditFormProps = {
  channel: Channel;
  editMode: boolean;
  onClose(): void;
};

type ChannelEditFormData = {
  id: number;
  assignmentGroup: AssignmentGroup;
};

function ChannelEditForm(props: ChannelEditFormProps) {
  const { channel, editMode, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [assignmentGroup, setAssignmentGroup] = useState<AssignmentGroup | undefined>(undefined);

  useEffect(() => {
    api
      .getAssignmentGroup(channel.assignmentGroupId)
      .then((assignmentGroup) => setAssignmentGroup(assignmentGroup));
  }, [api, channel]);

  const mutation = useMutation(
    (input: ChannelEditFormData) => {
      setAssignmentGroup(input.assignmentGroup);
      return api.updateChannel(input.id, input.assignmentGroup.id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['channel', channel.id]);
        onClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!assignmentGroup) {
      throw Error('No assignment group selected');
    }
    mutation.mutate({
      ...channel,
      assignmentGroup,
    });
  };

  const onCancel = () => {
    api
      .getAssignmentGroup(channel.assignmentGroupId)
      .then((assignmentGroup) => setAssignmentGroup(assignmentGroup));
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={editMode} onHide={onCancel} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Somethings not right...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Assignment Group</Form.Label>
            <AssignmentGroupDropdown value={assignmentGroup} onChange={setAssignmentGroup} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{ marginRight: '0.1rem' }}
          >
            Update
          </Button>
          <Button color="error" variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function ChannelDetailPage() {
  const api = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const params = useParams();

  const channelNumberId = parseInt(params.channelNumberId ?? '', 10);

  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    data: channelDetailPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(['channel', channelNumberId], async () => {
    const channel = await api.getChannel(channelNumberId);
    const assignmentGroup = await api.getAssignmentGroup(channel.assignmentGroupId);
    return { channel, assignmentGroup };
  });

  const deleteMutation = useMutation<void, Error, number>((id) => api.deleteChannel(id), {
    onSuccess: () => {
      // TODO Update this to match the query for the list page
      queryClient.invalidateQueries(['channels']);
      navigate('/channels');
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <Paper
        sx={{
          p: 2,
          margin: 'auto',
          marginTop: '1rem',
          maxWidth: 500,
          flexGrow: 1,
          borderRadius: '0.3rem',
          background:
            'linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container spacing={0.5}>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                  variant="subtitle1"
                  component="h5"
                >
                  {channelDetailPage.channel.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(`/assignmentGroups/${channelDetailPage.assignmentGroup.id}`)
                  }
                >
                  <span style={{ fontWeight: 'bold' }}>Assignment Group:</span>{' '}
                  {channelDetailPage.assignmentGroup.name ?? 'No Assignment Group'}{' '}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton aria-label="edit" color="primary" onClick={() => setEditMode(true)}>
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => deleteMutation.mutate(channelDetailPage.channel.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <ChannelEditForm
          channel={channelDetailPage.channel}
          editMode={editMode}
          onClose={() => setEditMode(false)}
        />
      </Paper>
    );
  }

  if (error instanceof Error) {
    return <div>{error.message}</div>;
  }

  if (deleteMutation.isError) {
    return <div>Failed to delete platform: {deleteMutation.error.message}</div>;
  }

  return <div>Error occurred</div>;
}
