import { useQuery } from '@tanstack/react-query';
import useApi from '../../../api';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { formatDate } from '../../../helper';

export default function YammerCommunityMembersBackupsPage() {
  const api = useApi();

  const params = useParams();
  const yammerGroupId = parseInt(params.yammerGroupId ?? '', 10);

  const {
    data: yammerCommunityMembersBackupsDetailPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(['yammerGroups', yammerGroupId], async () => {
    const yammerCommunityMembersBackups = await api.getYammerCommunityMembersBackup(yammerGroupId);
    return (
      await api.getYammerCommunityMembersBackupUsers(yammerCommunityMembersBackups.id)
    ).yammerCommunityMemberBackupUsers.map((data) => ({
      ...data,
      created_at: formatDate(data.created_at, true),
    }));
  });

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Created At',
        accessorKey: 'created_at',
      },
      {
        header: 'Emails',
        accessorKey: 'userEmail',
      },
    ],
    []
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <MaterialReactTable
        columns={columns}
        data={yammerCommunityMembersBackupsDetailPage}
        enableColumnResizing
        enableGrouping
        enableStickyHeader
        enableStickyFooter
        initialState={{
          density: 'compact',
          //   expanded: true, //expand all groups by default
          grouping: ['created_at'], //an array of columns to group by by default (can be multiple)
          pagination: { pageIndex: 0, pageSize: 20 },
          sorting: [{ id: 'created_at', desc: false }], //sort by state by default
        }}
        muiToolbarAlertBannerChipProps={{ color: 'primary' }}
        muiTableContainerProps={{ sx: { maxHeight: 370 } }}
      />
    );
  }

  if (error instanceof Error) {
    return <div>Failed to load yammer community members backups: {error.message}</div>;
  }

  return <div>Failed to load yammer community members backups</div>;
}
