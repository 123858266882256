import { useState, useEffect, ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';

import useApi from '../../api';
import { CAPAYear } from '../../models/CAPAYear';

type CAPAYearDropdownProps = {
  value?: CAPAYear;
  onChange(selectedCAPAYear?: CAPAYear): void;
};

export default function CAPAYearDropdown(props: CAPAYearDropdownProps) {
  const api = useApi();

  const [capaYears, setCAPAYears] = useState<CAPAYear[]>([]);

  useEffect(() => {
    // TODO handle multiple pages
    // TODO handle error
    api.getCAPAYears().then((capaYearPage) => {
      setCAPAYears(capaYearPage.capaYears);
    });
  }, [api]);

  const changeHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    if (!event.target.value) {
      console.error(`received undefined event.target.value from CAPA Year select`);
      return;
    }
    const capaYearId = parseInt(event.target.value, 10);
    const capaYear = capaYears.find((group) => group.id === capaYearId);
    if (!capaYear) {
      console.error(`CAPA Year with ID "${capaYearId}" not found`);
    }
    props.onChange(capaYear);
  };

  return (
    <Form.Select value={props.value?.id} onChange={changeHandler} aria-label="CAPA Year">
      <option>Select a Year</option>
      {capaYears.map((capaYear: CAPAYear) => {
        return (
          <option key={capaYear.id} value={capaYear.id}>
            {capaYear.year}
          </option>
        );
      })}
    </Form.Select>
  );
}
