import { useState, useEffect, ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';

import useApi from '../../api';
import { AssignmentGroup } from '../../models/AssignmentGroup';

type AssignmentGroupDropdownProps = {
  value?: AssignmentGroup;
  onChange(selectedAssignmentGroup?: AssignmentGroup): void;
};

export default function AssignmentGroupDropdown(props: AssignmentGroupDropdownProps) {
  const api = useApi();

  const [assignmentGroups, setAssignmentGroups] = useState<AssignmentGroup[]>([]);

  useEffect(() => {
    // TODO handle multiple pages
    // TODO handle error
    api.getAssignmentGroups().then((assignmentGroupPage) => {
      setAssignmentGroups(assignmentGroupPage.assignmentGroups);
    });
  }, [api]);

  const changeHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    if (!event.target.value) {
      console.error(`received undefined event.target.value from assignment group select`);
      return;
    }
    const assignmentGroupId = parseInt(event.target.value, 10);
    const assignmentGroup = assignmentGroups.find((group) => group.id === assignmentGroupId);
    if (!assignmentGroup) {
      console.error(`assignment group with ID "${assignmentGroupId}" not found`);
    }
    props.onChange(assignmentGroup);
  };

  return (
    <Form.Select value={props.value?.id} onChange={changeHandler} aria-label="Assignment Group">
      <option>Select an assignment group</option>
      {assignmentGroups.map((assignmentGroup: AssignmentGroup) => {
        return (
          <option key={assignmentGroup.id} value={assignmentGroup.id}>
            {assignmentGroup.name}
          </option>
        );
      })}
    </Form.Select>
  );
}
