import { useState, useEffect, ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';

import useApi from '../../api';
import { Platform } from '../../models/Platform';

type PlatformDropDownProps = {
  value?: Platform;
  onChange(selectedPlatform?: Platform): void;
};

export default function PlatformDropdown(props: PlatformDropDownProps) {
  const api = useApi();

  const [platforms, setPlatforms] = useState<Platform[]>([]);

  useEffect(() => {
    // TODO handle multiple pages
    // TODO handle error
    api.getPlatforms().then((platformGroupPage) => {
      setPlatforms(platformGroupPage.platforms);
    });
  }, [api]);

  const changeHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    if (!event.target.value) {
      console.error(`received undefined event.target.value from assignment group select`);
      return;
    }
    const platformId = parseInt(event.target.value, 10);
    const platform = platforms.find((group) => group.id === platformId);
    if (!platform) {
      console.error(`assignment group with ID "${platformId}" not found`);
    }
    props.onChange(platform);
  };

  return (
    <Form.Select value={props.value?.id} onChange={changeHandler} aria-label="Assignment Group">
      <option>Select an assignment group</option>
      {platforms.map((platform: Platform) => {
        return (
          <option key={platform.id} value={platform.id}>
            {platform.name}
          </option>
        );
      })}
    </Form.Select>
  );
}
