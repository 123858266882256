import { useEffect, useState, FormEvent } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import useApi from '../../../api';
import { trimEditedInput, getErrorMessage } from '../../../helper';
import { AssignmentGroup } from '../../../models/AssignmentGroup';

type AssignmentGroupEditFormProps = {
  assignmentGroup: AssignmentGroup;
  editMode: boolean;
  onClose(): void;
};

function AssignmentGroupEditForm(props: AssignmentGroupEditFormProps) {
  const { assignmentGroup, editMode, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setName(assignmentGroup.name);
  }, [assignmentGroup]);

  const mutation = useMutation(
    (input: AssignmentGroup) => {
      // Validate and clean up input fields
      const name = trimEditedInput(input.name);
      setName(name);

      return api.updateAssignmentGroup(input.id, name);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['assignmentGroup', assignmentGroup.id]);
        onClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.mutate({
      ...assignmentGroup,
      name,
    });
  };

  const onCancel = () => {
    setName(assignmentGroup.name);
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={editMode} onHide={onCancel} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Assignment Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Somethings not right...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Assignment Group</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{ marginRight: '0.1rem' }}
          >
            Update
          </Button>
          <Button color="error" variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function AssignmentGroupDetailPage() {
  const api = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const params = useParams();
  const assignmentGroupId = parseInt(params.assignmentGroupId ?? '', 10);

  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    data: assignmentGroupDetailPage,
    error,
    isLoading,
    isSuccess,
  } = useQuery(['assignmentGroup', assignmentGroupId], async () => {
    const assignmentGroup = await api.getAssignmentGroup(assignmentGroupId);
    const platforms = await api.getPlatforms();

    const platformIdName: {
      id: number;
      name: string;
    }[] = platforms.platforms.filter(
      (platform) => platform.assignmentGroupId === assignmentGroupId
    );

    return { assignmentGroup, platformIdName };
  });

  const deleteMutation = useMutation<void, Error, number>((id) => api.deleteAssignmentGroup(id), {
    onSuccess: () => {
      // TODO Update this to match the query for the list page
      queryClient.invalidateQueries(['assignmentGroups']);
      navigate('/assignmentGroups');
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <Paper
        sx={{
          p: 2,
          margin: 'auto',
          marginTop: '1rem',
          maxWidth: 500,
          flexGrow: 1,
          borderRadius: '0.3rem',
          background:
            'linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container spacing={0.5}>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                  variant="subtitle1"
                  component="h5"
                >
                  {assignmentGroupDetailPage.assignmentGroup.name}
                </Typography>
                <List
                  sx={{
                    marginTop: '1rem',
                    width: '100%',
                    maxWidth: 500,
                    bgcolor: '#001E3C',
                    color: 'white',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                    '& ul': { padding: 0 },
                    borderRadius: '0.3rem',
                  }}
                  subheader={<li />}
                >
                  <li>
                    <ul>
                      <ListSubheader>{`Platforms`}</ListSubheader>
                      {assignmentGroupDetailPage.platformIdName.map((platform) => (
                        <ListItemButton
                          key={platform.id}
                          component="a"
                          href={`/platforms/${platform.id}`}
                        >
                          <ListItemText
                            primary={
                              <Typography gutterBottom variant="subtitle2">
                                {platform.name}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </ul>
                  </li>
                </List>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton aria-label="edit" color="primary" onClick={() => setEditMode(true)}>
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => deleteMutation.mutate(assignmentGroupDetailPage.assignmentGroup.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <AssignmentGroupEditForm
          assignmentGroup={assignmentGroupDetailPage.assignmentGroup}
          editMode={editMode}
          onClose={() => setEditMode(false)}
        />
      </Paper>
    );
  }

  if (error instanceof Error) {
    return <div>{error.message}</div>;
  }
  if (deleteMutation.isError) {
    return <div>Failed to delete assignment group: {deleteMutation.error.message}</div>;
  }
  return <div>Error occurred</div>;
}
