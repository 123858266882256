import { useState, FormEvent } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import useApi from '../../api';
import ListingCards from '../../components/ListingCards/ListingCards';
import CardsPagination from '../../components/CardsPagination/CardsPagination';
import { trimEditedInput, getErrorMessage } from '../../helper';
import { PaginationInfo } from '../../models/PaginationInfo';

type CAPAYearCreateFormProps = {
  isCardModalDisplayed: boolean;
  onClose(): void;
};

type CAPAYearCreateFormData = {
  jiraInitiative: string;
  year: number;
};

function CAPAYearCreateForm(props: CAPAYearCreateFormProps) {
  const { isCardModalDisplayed, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [jiraInitiative, setJiraInitiative] = useState<string>('');
  const [year, setYear] = useState<number>(2000);

  const mutation = useMutation(
    (input: CAPAYearCreateFormData) => {
      const trimmedJiraInitiative = trimEditedInput(input.jiraInitiative);
      setJiraInitiative(trimmedJiraInitiative);
      setYear(input.year);

      return api.createCAPAYear(trimmedJiraInitiative, input.year);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['capaYears']);
        resetAndClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.mutate({ jiraInitiative, year });
  };

  const resetAndClose = () => {
    setJiraInitiative('');
    setYear(2000);
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={isCardModalDisplayed} onHide={resetAndClose} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Create a CAPA Based on Year</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Something went wrong...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Parent Link</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={jiraInitiative}
              onChange={(e) => setJiraInitiative(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Year</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={year}
              onChange={(e) => setYear(Number(e.target.value))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Save
          </Button>
          <Button variant="secondary" onClick={resetAndClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function CAPAYears() {
  const api = useApi();

  const [isCardModalDisplayed, setIsCardModalDisplayed] = useState<boolean>(false);
  const [cursor, setCursor] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationInfo>({
    pageSize: 10,
    next: 0,
    previous: null,
  });

  const {
    data: capaYearsPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(
    ['capaYears', cursor],
    () => {
      return api.getCAPAYears(cursor);
    },
    {
      onSuccess: (data) => setPagination(data.pagination),
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <div>
        <ListingCards
          cards={capaYearsPage.capaYears.map((capaYear) => ({
            id: capaYear.id,
            title: capaYear.year.toString(),
            link: `/capaYears/${capaYear.id}`,
          }))}
        />
        <div>
          <Button variant="success" onClick={() => setIsCardModalDisplayed(true)}>
            Create a CAPA based on Year
          </Button>
        </div>
        <CardsPagination
          pagination={pagination}
          onPageChange={(newCursor) => setCursor(newCursor)}
        />
        <CAPAYearCreateForm
          isCardModalDisplayed={isCardModalDisplayed}
          onClose={() => setIsCardModalDisplayed(false)}
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return <div>Failed to load CAPAs Based on Year: {error.message}</div>;
  }

  return <div>Failed to load CAPAs Based on Year</div>;
}
