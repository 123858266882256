import { useState, FormEvent } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import useApi from '../../api';
import ListingCards from '../../components/ListingCards/ListingCards';
import CardsPagination from '../../components/CardsPagination/CardsPagination';
import { trimEditedInput, getErrorMessage } from '../../helper';
import { PaginationInfo } from '../../models/PaginationInfo';

type AssignmentGroupCreateFormProps = {
  isCardModalDisplayed: boolean;
  onClose(): void;
};

function AssignmentGroupCreateForm(props: AssignmentGroupCreateFormProps) {
  const { isCardModalDisplayed, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [name, setName] = useState('');

  const mutation = useMutation(
    (name: string) => {
      const trimmedName = trimEditedInput(name);
      setName(trimmedName);

      return api.createAssignmentGroup(trimmedName);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['assignmentGroups']);
        resetAndClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.mutate(name);
  };

  const resetAndClose = () => {
    setName('');
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={isCardModalDisplayed} onHide={resetAndClose} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Create Assignment Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Something went wrong...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Save
          </Button>
          <Button variant="secondary" onClick={resetAndClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function AssignmentGroups() {
  const api = useApi();

  const [isCardModalDisplayed, setIsCardModalDisplayed] = useState<boolean>(false);
  const [cursor, setCursor] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationInfo>({
    pageSize: 10,
    next: 0,
    previous: null,
  });

  const {
    data: assignmentGroupsPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(
    ['assignmentGroups', cursor],
    () => {
      return api.getAssignmentGroups(cursor);
    },
    {
      onSuccess: (data) => setPagination(data.pagination),
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <div>
        <ListingCards
          cards={assignmentGroupsPage.assignmentGroups.map((assignmentGroup) => ({
            id: assignmentGroup.id,
            title: assignmentGroup.name,
            link: `/assignmentGroups/${assignmentGroup.id}`,
          }))}
        />
        <div>
          <Button variant="success" onClick={() => setIsCardModalDisplayed(true)}>
            Create an Assignment Group
          </Button>
        </div>
        <CardsPagination
          pagination={pagination}
          onPageChange={(newCursor) => setCursor(newCursor)}
        />
        <AssignmentGroupCreateForm
          isCardModalDisplayed={isCardModalDisplayed}
          onClose={() => setIsCardModalDisplayed(false)}
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return <div>Failed to load assignment groups: {error.message}</div>;
  }

  return <div>Failed to load assignment groups</div>;
}
