import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './App.scss';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Routing from './Routing/Routing';
import { config } from '../config';

type AppProps = {
  msalInstance: IPublicClientApplication;
};

export default function App(props: AppProps) {
  const { msalInstance } = props;
  const queryClient = new QueryClient();

  // TODO Should we integrate a custom navigation client?
  // const history = useHistory();
  // const navigationClient = new CustomNavigationClient(history);
  // msalInstance.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={msalInstance}>
      <div className="App">
        <Header />
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{
            scopes: config.azureAd.scopes,
          }}
          // TODO error and loading components for auth
          // errorComponent={ErrorComponent}
          // loadingComponent={Loading}
        >
          <QueryClientProvider client={queryClient}>
            <Routing />
          </QueryClientProvider>
        </MsalAuthenticationTemplate>
        <Footer />
      </div>
    </MsalProvider>
  );
}
