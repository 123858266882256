/*
 * Loads environment dependent values.
 *
 * WARNING: Environment variable values are read at build time and are
 * embedded into the JavaScript bundle. DO NOT USE FRONTEND ENVIRONMENT
 * VARIABLES FOR SECRET VALUES.
 *
 * All environment variables must start with "REACT_APP_"
 *
 * https://create-react-app.dev/docs/adding-custom-environment-variables/
 */

export type FrontendConfig = {
  azureAd: {
    tenantId: string;
    clientId: string;
    scopes: string[];
  };
  api: {
    baseUrl: string;
  };
};

function assertEnvVar(name: string, defaultValue?: string): string {
  const value = process.env[name];
  if (value) {
    return value;
  }
  if (defaultValue) {
    return defaultValue;
  }
  throw new Error(`Environment variable "${name}" must be set!`);
}

const lillyMicrosoftTenantId = '18a59a81-eea8-4c30-948a-d8824cdc2580';
const lillyOidcScopes = 'openid email profile api://39b64b04-e385-4232-a4e2-604367e2d849/.default';

export const config: FrontendConfig = {
  azureAd: {
    tenantId: assertEnvVar('REACT_APP_AZURE_AD_TENANT', lillyMicrosoftTenantId),
    clientId: assertEnvVar('REACT_APP_AZURE_AD_CLIENT_ID'),
    scopes: assertEnvVar('REACT_APP_AZURE_AD_SCOPES', lillyOidcScopes).split(/\s+/),
  },
  api: {
    baseUrl: assertEnvVar('REACT_APP_API_BASE_URL', 'https://localhost:3000'),
  },
};
