import useApi from '../../../api';
import { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Alert, Form, Modal, Row } from 'react-bootstrap';
import { OutageNotification } from '../../../models/OutageNotification';
import { Platform } from '../../../models/Platform';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { getErrorMessage, trimEditedInput } from '../../../helper';
import { YammerPost } from '../../../models/YammerPost';
import PlatformDropdown from '../../../components/PlatformDropdown/PlatformDropdown';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { formatDate } from '../../../helper';

type OutageNotificationEditFormProps = {
  outageNotification: OutageNotification;
  editMode: boolean;
  onClose(): void;
};

type OutageNotificationEditFormData = {
  id: number;
  impactType: string;
  platform: Platform;
  titleText: string;
  issueType: string;
  vendorLinkText: string | null;
  bodyText: string | null;
};

function OutageNotificationEditForm(props: OutageNotificationEditFormProps) {
  const { outageNotification, editMode, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const impactTypes: string[] = ['Known Impact', 'Unknown Impact'];
  const [impactType, setImpactType] = useState<string>(impactTypes[0]);

  const [titleText, setTitleText] = useState<string>('');

  const [platform, setPlatform] = useState<Platform | undefined>(undefined);

  const issueTypes: string[] = ['Outage', 'Service Degredation'];
  const [issueType, setIssueType] = useState<string>(issueTypes[0]);

  const [vendorLinkText, setVendorLinkText] = useState<string>('');

  const [bodyText, setBodyText] = useState<string>('');

  useEffect(() => {
    setImpactType(outageNotification.impactType);
    setTitleText(outageNotification.titleText);
    setIssueType(outageNotification.typeOfIssue);
    api.getPlatform(outageNotification.impactedToolId).then((platform) => setPlatform(platform));
    if (outageNotification.vendorLink !== null) {
      setVendorLinkText(outageNotification.vendorLink);
    } else {
      setVendorLinkText('');
    }
    if (outageNotification.bodyText !== null) {
      setBodyText(outageNotification.bodyText);
    } else {
      setBodyText('');
    }
  }, [api, outageNotification]);

  const mutation = useMutation(
    (input: OutageNotificationEditFormData) => {
      const trimmedTextTiltle = trimEditedInput(titleText);
      const trimmedBodyText = trimEditedInput(bodyText);
      setPlatform(input.platform);
      const yammerPost: YammerPost = {
        impactType: impactType,
        impactToolID: input.platform.id,
        titleText: trimmedTextTiltle,
        typeOfIssue: issueType,
        vendorLink: vendorLinkText,
        bodyText: trimmedBodyText,
        status: outageNotification.status,
      };
      return api.updateOutageNotification(input.id, yammerPost);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['outageNotification', outageNotification.id]);
        onClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (titleText === '') {
      throw new Error('Must include title before submission');
    }
    if (!platform) {
      throw Error('No platform selected');
    }
    mutation.mutate({
      ...outageNotification,
      impactType,
      platform,
      titleText,
      issueType,
      vendorLinkText,
      bodyText,
    });
  };

  const onCancel = () => {
    setImpactType(outageNotification.impactType);
    setTitleText(outageNotification.titleText);
    setIssueType(outageNotification.typeOfIssue);
    if (outageNotification.vendorLink !== null) {
      setBodyText(outageNotification.vendorLink);
    } else {
      setBodyText('');
    }
    if (outageNotification.vendorLink !== null) {
      setVendorLinkText(outageNotification.vendorLink);
    } else {
      setVendorLinkText('');
    }
    api.getPlatform(outageNotification.impactedToolId).then((platform) => setPlatform(platform));
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={editMode} onHide={onCancel} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Outage Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Somethings not right...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <div>
            <Row>
              <Form.Group>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check
                    inline
                    label="Known Impact"
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    checked={impactType === impactTypes[0]}
                    value={impactType}
                    onChange={() => setImpactType(impactTypes[0])}
                  />
                  <Form.Check
                    inline
                    label="Unknown Impact"
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    checked={impactType === impactTypes[1]}
                    value={impactType}
                    onChange={() => setImpactType(impactTypes[1])}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  className="w-50"
                  type="text"
                  value={titleText}
                  autoFocus
                  onChange={(e) => setTitleText(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                <Form.Label>Select a Platform</Form.Label>
                <PlatformDropdown value={platform} onChange={setPlatform} />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                Select Type of Issue
                <Form.Select
                  className="w-50"
                  aria-label="Default select example"
                  value={issueType}
                  onChange={(e) => setIssueType(e.target.value)}
                >
                  {issueTypes.map((issueType) => {
                    return (
                      <option key={issueType} value={issueType}>
                        {issueType}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Link to Vendor Link</Form.Label>
                <Form.Control
                  className="w-50"
                  type="text"
                  value={vendorLinkText}
                  autoFocus
                  onChange={(e) => setVendorLinkText(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Body</Form.Label>
              <Form.Control
                className="w-51"
                as="textarea"
                value={bodyText}
                autoFocus
                onChange={(e) => setBodyText(e.target.value)}
                rows={3}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{ marginRight: '0.1rem' }}
          >
            Update
          </Button>
          <Button color="error" variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function OutageNotificationDetailPage() {
  const api = useApi();

  const params = useParams();
  const outageNotificationId = parseInt(params.outageNotificationId ?? '', 10);

  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    data: outageNotificationDetailPage,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery(['outageNotification', outageNotificationId], async () => {
    const outageNotification = await api.getOutageNotification(outageNotificationId);
    return { outageNotification };
  });

  const handleStatus = async (outageNotification: OutageNotification) => {
    let currentStatus = 'active';
    if (outageNotification.status === 'active') {
      currentStatus = 'resolved';
    }
    const yammerPost: YammerPost = {
      impactType: outageNotification.impactType,
      impactToolID: outageNotification.impactedToolId,
      titleText: outageNotification.titleText,
      typeOfIssue: outageNotification.typeOfIssue,
      vendorLink: outageNotification.vendorLink,
      bodyText: outageNotification.bodyText,
      status: currentStatus,
    };
    try {
      await api.updateOutageNotification(outageNotification.id, yammerPost).then();
      refetch();
    } catch {
      throw Error('Update Failed');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <Paper
        sx={{
          p: 2,
          margin: 'auto',
          marginTop: '1rem',
          maxWidth: 500,
          flexGrow: 1,
          borderRadius: '0.3rem',
          background:
            'linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography
              style={{ fontWeight: 'bold' }}
              gutterBottom
              variant="subtitle1"
              component="h5"
            >
              {outageNotificationDetailPage.outageNotification.titleText} (Status:{' '}
              {outageNotificationDetailPage.outageNotification.status.toUpperCase()})
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {outageNotificationDetailPage.outageNotification.impactedToolName ?? 'No platform'} -{' '}
              {outageNotificationDetailPage.outageNotification.typeOfIssue} -{' '}
              {outageNotificationDetailPage.outageNotification.impactType}
            </Typography>
            <Typography variant="body1">
              {outageNotificationDetailPage.outageNotification.bodyText}
            </Typography>
            <Typography display="block" variant="caption">
              {outageNotificationDetailPage.outageNotification.vendorLink !== '' ? (
                <>
                  Vendor Link:{' '}
                  <Card.Link href={`${outageNotificationDetailPage.outageNotification.vendorLink}`}>
                    {outageNotificationDetailPage.outageNotification.vendorLink}
                  </Card.Link>{' '}
                </>
              ) : null}
            </Typography>
            <Typography display="block" variant="caption">
              Created At: {formatDate(outageNotificationDetailPage.outageNotification.createdAt)}
            </Typography>
            <Typography display="block" variant="caption">
              Updated At: {formatDate(outageNotificationDetailPage.outageNotification.updatedAt)}
            </Typography>
          </Grid>
        </Grid>
        <Button
          style={{ marginTop: '0.6rem' }}
          variant="contained"
          onClick={() => handleStatus(outageNotificationDetailPage.outageNotification)}
          color={
            outageNotificationDetailPage.outageNotification.status === 'active'
              ? 'success'
              : 'secondary'
          }
        >
          {outageNotificationDetailPage.outageNotification.status === 'active'
            ? 'MARK AS RESOLVED'
            : 'MARK AS ACTIVE'}
        </Button>{' '}
        <Button
          style={{ marginTop: '0.6rem' }}
          variant="contained"
          color="primary"
          onClick={() => setEditMode(true)}
          endIcon={<EditIcon />}
        >
          Edit
        </Button>
        <OutageNotificationEditForm
          outageNotification={outageNotificationDetailPage.outageNotification}
          editMode={editMode}
          onClose={() => setEditMode(false)}
        />
      </Paper>
    );
  }

  if (error instanceof Error) {
    return <div>{error.message}</div>;
  }

  return <div>Error occurred</div>;
}
