import { FormEvent } from 'react';
import useApi from '../../api';
import { Platform } from '../../models/Platform';
import { YammerPost } from '../../models/YammerPost';
import { Alert, Button, Form, Modal, Row } from 'react-bootstrap';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { getErrorMessage, trimEditedInput } from '../../helper';
import PlatformDropdown from '../../components/PlatformDropdown/PlatformDropdown';
import { useMemo, useState } from 'react';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../helper';
import { capitalizeFirstLetter } from '../../helper';

type OutageNotificationCreateFormProps = {
  isCardModalDisplayed: boolean;
  onClose(): void;
};

type OutageNotificationCreateFormData = {
  impactType: string;
  platform: Platform;
  titleText: string;
  issueType: string;
  vendorLinkText: string | null;
  bodyText: string | null;
};

function OutageNotificationCreateForm(props: OutageNotificationCreateFormProps) {
  const { isCardModalDisplayed, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const impactTypes: string[] = ['Known Impact', 'Unknown Impact'];
  const [impactType, setImpactType] = useState<string>(impactTypes[0]);

  const [titleText, setTitleText] = useState<string>('');

  const [platform, setPlatform] = useState<Platform | undefined>(undefined);

  const issueTypes: string[] = ['Outage', 'Service Degredation'];
  const [issueType, setIssueType] = useState<string>(issueTypes[0]);

  const [vendorLinkText, setVendorLinkText] = useState<string>('');

  const [bodyText, setBodyText] = useState<string>('');

  const [error, setError] = useState<string>('');

  const mutation = useMutation(
    (input: OutageNotificationCreateFormData) => {
      const trimmedTextTiltle = trimEditedInput(titleText);
      const trimmedBodyText = trimEditedInput(bodyText);
      setPlatform(input.platform);
      const yammerPost: YammerPost = {
        impactType: impactType,
        impactToolID: input.platform.id,
        titleText: trimmedTextTiltle,
        typeOfIssue: issueType,
        vendorLink: vendorLinkText,
        bodyText: trimmedBodyText,
        status: 'active',
      };
      return api.postOutageNotification(yammerPost);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['outageNotifications']);
        resetAndClose();
      },
    }
  );

  const resetAndClose = () => {
    setTitleText('');
    setBodyText('');
    setVendorLinkText('');
    setError('');
    setPlatform(undefined);
    mutation.reset();
    onClose();
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (titleText === '') {
      setError('Must include title before submission');
    }
    if (!platform) {
      throw new Error('No platform selected');
    }
    mutation.mutate({
      impactType,
      platform,
      titleText,
      issueType,
      vendorLinkText,
      bodyText,
    });
  };

  return (
    <Modal show={isCardModalDisplayed} onHide={resetAndClose} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Create OutageNotification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {error !== '' && mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Something went wrong...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Row>
            <Form.Group>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Known Impact"
                  name="group1"
                  type="radio"
                  id={`inline-radio-1`}
                  checked={impactType === impactTypes[0]}
                  value={impactType}
                  onChange={() => setImpactType(impactTypes[0])}
                />
                <Form.Check
                  inline
                  label="Unknown Impact"
                  name="group1"
                  type="radio"
                  id={`inline-radio-2`}
                  checked={impactType === impactTypes[1]}
                  value={impactType}
                  onChange={() => setImpactType(impactTypes[1])}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                className="w-50"
                type="text"
                value={titleText}
                autoFocus
                onChange={(e) => setTitleText(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Select a Platform</Form.Label>
              <PlatformDropdown value={platform} onChange={setPlatform} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              Select Type of Issue
              <Form.Select
                className="w-50"
                aria-label="Default select example"
                value={issueType}
                onChange={(e) => setIssueType(e.target.value)}
              >
                {issueTypes.map((issueType) => {
                  return (
                    <option key={issueType} value={issueType}>
                      {issueType}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Link to Vendor Link</Form.Label>
              <Form.Control
                className="w-50"
                type="text"
                value={vendorLinkText}
                autoFocus
                onChange={(e) => setVendorLinkText(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Body</Form.Label>
            <Form.Control
              className="w-51"
              as="textarea"
              value={bodyText}
              autoFocus
              onChange={(e) => setBodyText(e.target.value)}
              rows={3}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Save
          </Button>
          <Button variant="secondary" onClick={resetAndClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function OutageNotifications() {
  const api = useApi();

  type RowAttributes = {
    id: number;
    title: string;
    issue: string;
    type: string;
    platformName: string;
    status: string;
    createdAt: string;
  };

  const [outageNotificationRow, setOutageNotificationRow] = useState<RowAttributes[]>([]);

  const [isCardModalDisplayed, setisCardModalDisplayed] = useState<boolean>(false);

  const { isLoading, isSuccess, error } = useQuery(
    ['outageNotifications'],
    () => api.getOutageNotifications(),
    {
      onSuccess: (data) => {
        const rowAttributes: RowAttributes[] = [];
        data.outageNotifications.forEach((outageNotification) => {
          rowAttributes.push({
            id: outageNotification.id,
            title: outageNotification.titleText,
            status: capitalizeFirstLetter(outageNotification.status),
            issue: outageNotification.typeOfIssue,
            type: outageNotification.impactType,
            platformName: outageNotification.impactedToolName,
            createdAt: formatDate(outageNotification.createdAt),
          });
        });
        setOutageNotificationRow(rowAttributes);
      },
    }
  );

  const columns = useMemo<MRT_ColumnDef<RowAttributes>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        muiTableHeadCellProps: { sx: { color: 'red' } },
      },
      {
        accessorKey: 'title',
        header: 'Title',
        muiTableHeadCellProps: { sx: { color: 'green' } },
      },
      {
        accessorKey: 'issue',
        header: 'Type of Issue',
        muiTableHeadCellProps: { sx: { color: 'blue' } },
      },
      {
        accessorKey: 'type',
        header: 'Impact Type',
        muiTableHeadCellProps: { sx: { color: 'orange' } },
      },
      {
        accessorKey: 'platformName',
        header: 'Platform',
        muiTableHeadCellProps: { sx: { color: 'purple' } },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        muiTableHeadCellProps: { sx: { color: 'red' } },
        enableHiding: true,
      },
      {
        accessorKey: 'createdAt',
        header: 'Date Created',
        muiTableHeadCellProps: { sx: { color: 'red' } },
        enableHiding: true,
      },
    ],
    []
  );
  const navigate = useNavigate();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <div>
        <OutageNotificationCreateForm
          isCardModalDisplayed={isCardModalDisplayed}
          onClose={() => setisCardModalDisplayed(false)}
        />
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={columns}
          data={outageNotificationRow}
          editingMode="modal"
          enableColumnOrdering
          enableEditing
          renderRowActions={({ row }) => (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={() => navigate(`${row.getValue('id')}`)}>View Details</Button>
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <Button variant="success" onClick={() => setisCardModalDisplayed(true)}>
              Create an outage notification
            </Button>
          )}
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return <div>Failed to load outage notifactions: {error.message}</div>;
  }

  return <div>Failed to load outage notifactions</div>;
}
