import { FormEvent, useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import useApi from '../../../api';
import AssignmentGroupDropdown from '../../../components/AssignmentGroupDropdown/AssignmentGroupDropdown';
import { getErrorMessage, trimEditedInput } from '../../../helper';
import { Platform } from '../../../models/Platform';
import { AssignmentGroup } from '../../../models/AssignmentGroup';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

type PlatformEditFormProps = {
  platform: Platform;
  editMode: boolean;
  onClose(): void;
};

type PlatformEditFormData = {
  id: number;
  name: string;
  assignmentGroup: AssignmentGroup;
};

function PlatformEditForm(props: PlatformEditFormProps) {
  const { platform, editMode, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [name, setName] = useState<string>('');
  const [assignmentGroup, setAssignmentGroup] = useState<AssignmentGroup | undefined>(undefined);

  useEffect(() => {
    setName(platform.name);
    api
      .getAssignmentGroup(platform.assignmentGroupId)
      .then((assignmentGroup) => setAssignmentGroup(assignmentGroup));
  }, [api, platform]);

  const mutation = useMutation(
    (input: PlatformEditFormData) => {
      const trimmedName = trimEditedInput(input.name);
      setName(trimmedName);
      setAssignmentGroup(input.assignmentGroup);

      return api.updatePlatform(input.id, trimmedName, input.assignmentGroup.id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['platform', platform.id]);
        onClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!assignmentGroup) {
      throw Error('No assignment group selected');
    }
    mutation.mutate({
      ...platform,
      name,
      assignmentGroup,
    });
  };

  const onCancel = () => {
    setName(platform.name);
    api
      .getAssignmentGroup(platform.assignmentGroupId)
      .then((assignmentGroup) => setAssignmentGroup(assignmentGroup));
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={editMode} onHide={onCancel} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Platform</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Somethings not right...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Platform</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Assignment Group</Form.Label>
            <AssignmentGroupDropdown value={assignmentGroup} onChange={setAssignmentGroup} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{ marginRight: '0.1rem' }}
          >
            Update
          </Button>
          <Button color="error" variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function PlatformDetailPage() {
  const api = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const params = useParams();
  const platformId = parseInt(params.platformId ?? '', 10);

  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    data: platformDetailPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(['platform', platformId], async () => {
    const platform = await api.getPlatform(platformId);
    const assignmentGroup = await api.getAssignmentGroup(platform.assignmentGroupId);
    const channels = await api.getChannels();
    let channelsIdName: { id: number; name: string }[] = [];
    channels.channels.forEach((channel) => {
      if (channel.assignmentGroupId === platform.assignmentGroupId)
        channelsIdName.push({ id: channel.id, name: channel.name });
    });
    return { platform, assignmentGroup, channelsIdName };
  });

  const deleteMutation = useMutation<void, Error, number>((id) => api.deletePlatform(id), {
    onSuccess: () => {
      // TODO Update this to match the query for the list page
      queryClient.invalidateQueries(['platforms']);
      navigate('/platforms');
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <Paper
        sx={{
          p: 2,
          margin: 'auto',
          marginTop: '1rem',
          maxWidth: 500,
          flexGrow: 1,
          borderRadius: '0.3rem',
          background:
            'linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container spacing={0.5}>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                  variant="subtitle1"
                  component="h5"
                >
                  {platformDetailPage.platform.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(`/assignmentGroups/${platformDetailPage.assignmentGroup.id}`)
                  }
                >
                  <span style={{ fontWeight: 'bold' }}>Assignment Group:</span>{' '}
                  {platformDetailPage.assignmentGroup.name ?? 'No Assignment Group'}{' '}
                </Typography>
                <List
                  sx={{
                    marginTop: '1rem',
                    width: '100%',
                    maxWidth: 500,
                    bgcolor: '#001E3C',
                    color: 'white',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                    '& ul': { padding: 0 },
                    borderRadius: '0.3rem',
                  }}
                  subheader={<li />}
                >
                  <li>
                    <ul>
                      <ListSubheader>{`Slack Channels`}</ListSubheader>
                      {platformDetailPage.channelsIdName.map((channel) => (
                        <ListItemButton
                          key={channel.id}
                          component="a"
                          href={`/channels/${channel.id}`}
                        >
                          <ListItemText
                            primary={
                              <Typography gutterBottom variant="subtitle2">
                                {channel.name}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </ul>
                  </li>
                </List>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton aria-label="edit" color="primary" onClick={() => setEditMode(true)}>
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => deleteMutation.mutate(platformDetailPage.platform.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <PlatformEditForm
          platform={platformDetailPage.platform}
          editMode={editMode}
          onClose={() => setEditMode(false)}
        />
      </Paper>
    );
  }

  if (error instanceof Error) {
    return <div>{error.message}</div>;
  }

  if (deleteMutation.isError) {
    return <div>Failed to delete platform: {deleteMutation.error.message}</div>;
  }
  return <div>Error occurred</div>;
}
