import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';

export default function NavigationBar() {
  return (
    <div>
      <Navbar bg="dark" variant={'dark'} expand="lg">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            EDAT Ops Bot
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="edatOpsBot" />
          <Navbar.Collapse id="edatOpsBot">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
              <Nav.Link as={Link} to="/platforms">
                Platforms
              </Nav.Link>
              <Nav.Link as={Link} to="/channels">
                Channels
              </Nav.Link>
              <Nav.Link as={Link} to="/assignmentGroups">
                Assignment Groups
              </Nav.Link>
              <Nav.Link as={Link} to="/outageNotifications">
                Outage Notification
              </Nav.Link>
              <Nav.Link as={Link} to="/yammerGroups">
                Yammer Group Registration
              </Nav.Link>
              <Nav.Link as={Link} to="/capaYears">
                CAPAs Year Records
              </Nav.Link>
              <Nav.Link as={Link} to="/capas">
                CAPAs Information
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
