import { FormEvent, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import useApi from '../../api';
import ListingCards from '../../components/ListingCards/ListingCards';
import CardsPagination from '../../components/CardsPagination/CardsPagination';
import { getErrorMessage, trimEditedInput } from '../../helper';
import { PaginationInfo } from '../../models/PaginationInfo';
import { CAPAYear } from '../../models/CAPAYear';
import CAPAYearDropdown from '../../components/CAPAYearDropdown/CAPAYearDropdown';

type CAPACreateFormProps = {
  isCardModalDisplayed: boolean;
  onClose(): void;
};

type CAPACreateFormData = {
  snowCAPAId: string;
  jiraEpicId: string;
  //reference to the CAPAYear table
  capaYear: CAPAYear;
};

function CAPACreateForm(props: CAPACreateFormProps) {
  const { isCardModalDisplayed, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [snowCAPAId, setSNOWCAPAId] = useState<string>('');
  const [jiraEpicId, setJIRAEpicId] = useState<string>('');
  const [capaYear, setCAPAYear] = useState<CAPAYear | undefined>(undefined);

  const mutation = useMutation(
    (input: CAPACreateFormData) => {
      const trimmedSNOWCAPAId = trimEditedInput(input.snowCAPAId);
      setSNOWCAPAId(trimmedSNOWCAPAId);
      const trimmedJIRAEpicId = trimEditedInput(input.jiraEpicId);
      setJIRAEpicId(trimmedJIRAEpicId);
      setCAPAYear(input.capaYear);

      return api.createCAPA(trimmedSNOWCAPAId, trimmedJIRAEpicId, input.capaYear.id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['capas']);
        resetAndClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!capaYear) {
      throw Error('No CAPAS Year Records selected');
    }
    mutation.mutate({ snowCAPAId, jiraEpicId, capaYear });
  };

  const resetAndClose = () => {
    setSNOWCAPAId('');
    setJIRAEpicId('');
    setCAPAYear(undefined);
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={isCardModalDisplayed} onHide={resetAndClose} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Create CAPA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Something went wrong...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>ServiceNow CAPA ID</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={snowCAPAId}
              onChange={(e) => setSNOWCAPAId(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Jira Epic ID</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={jiraEpicId}
              onChange={(e) => setJIRAEpicId(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>CAPA Year</Form.Label>
            <CAPAYearDropdown value={capaYear} onChange={setCAPAYear} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Save
          </Button>
          <Button variant="secondary" onClick={resetAndClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function CAPAs() {
  const api = useApi();

  const [isCardModalDisplayed, setIsCardModalDisplayed] = useState<boolean>(false);
  const [cursor, setCursor] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationInfo>({
    pageSize: 10,
    next: null,
    previous: null,
  });

  const {
    data: capasPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(
    ['capas', cursor],
    () => {
      return api.getCAPAs(cursor);
    },
    {
      onSuccess: (data) => setPagination(data.pagination),
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <div>
        <ListingCards
          cards={capasPage.capas.map((capa) => ({
            id: capa.id,
            title: capa.snowCAPAId,
            link: `/capas/${capa.id}`,
          }))}
        />
        <div>
          <Button variant="success" onClick={() => setIsCardModalDisplayed(true)}>
            Create a CAPA
          </Button>
        </div>
        <CardsPagination
          pagination={pagination}
          onPageChange={(newCursor) => setCursor(newCursor)}
        />
        <CAPACreateForm
          isCardModalDisplayed={isCardModalDisplayed}
          onClose={() => setIsCardModalDisplayed(false)}
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return <div>Failed to load capas: {error.message}</div>;
  }

  return <div>Failed to load capas</div>;
}
