import { Route, Routes } from 'react-router-dom';
import AssignmentGroupDetailPage from '../../pages/AssignmentGroups/_id';
import AssignmentGroups from '../../pages/AssignmentGroups';
import ChannelDetailPage from '../../pages/Channels/_id';
import Channels from '../../pages/Channels';
import HomePage from '../../pages/Homepage';
import PlatformDetailPage from '../../pages/Platforms/_id';
import Platforms from '../../pages/Platforms';
import OutageNotification from '../../pages/OutageNotification';
import OutageNotificationDetailPage from '../../pages/OutageNotification/_id';
import YammerGroups from '../../pages/YammerGroup';
import CAPAYears from '../../pages/CAPAYears';
import CAPAYearDetailPage from '../../pages/CAPAYears/_id';
import CAPAs from '../../pages/CAPAs';
import CAPADetailPage from '../../pages/CAPAs/_id';
import YammerCommunityMembersBackupsPage from '../../pages/YammerGroup/_id';

export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/platforms" element={<Platforms />} />
      <Route path="/platforms/:platformId" element={<PlatformDetailPage />} />
      <Route path="/channels" element={<Channels />} />
      <Route path="/channels/:channelNumberId" element={<ChannelDetailPage />} />
      <Route path="/assignmentGroups" element={<AssignmentGroups />} />
      <Route path="/assignmentGroups/:assignmentGroupId" element={<AssignmentGroupDetailPage />} />
      <Route path="/outageNotifications" element={<OutageNotification />} />
      <Route
        path="/outageNotifications/:outageNotificationId"
        element={<OutageNotificationDetailPage />}
      />
      <Route path="/yammerGroups" element={<YammerGroups />} />

      <Route path="/yammerGroups/:yammerGroupId" element={<YammerCommunityMembersBackupsPage />} />
      <Route path="/capaYears" element={<CAPAYears />} />
      <Route path="/capaYears/:capaYearId" element={<CAPAYearDetailPage />} />
      <Route path="/capas" element={<CAPAs />} />
      <Route path="/capas/:capaId" element={<CAPADetailPage />} />
      <Route
        path="*"
        element={
          <main style={{ padding: '1rem' }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
}
