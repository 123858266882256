import { Card as BootstrapCard } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export type Card = {
  id: number;
  title: string;
  link: string;
};

type ListingCardsProps = {
  cards: Card[];
};

export default function ListingCards(props: ListingCardsProps) {
  return (
    <ul
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: '1rem',
      }}
      className="d-flex flex-wrap bd-highlight example-parent list-unstyled d-grid gap-5"
    >
      {props.cards.map((card) => {
        return (
          <li key={card.id}>
            <div className="card" style={{ width: '18rem' }}>
              <div>
                <BootstrapCard
                  className="bg-image hover-overlay"
                  style={{
                    width: '18rem',
                    background:
                      'linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)',
                  }}
                >
                  <BootstrapCard.Body>
                    <BootstrapCard.Title>{card.title}</BootstrapCard.Title>
                    <BootstrapCard.Link as={Link} to={card.link} className="stretched-link" />
                  </BootstrapCard.Body>
                </BootstrapCard>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
