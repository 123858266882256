import { Constants } from './models/Constants';
import { DateTime } from 'luxon';

export function trimEditedInput(editedInput: string): string {
  const trimmedEditedInput = editedInput.trim();
  if (trimmedEditedInput.length < Constants.numberOfMinimumCharactersForName) {
    throw RangeError(
      `The input value needs to be at least ${Constants.numberOfMinimumCharactersForName} characters long`
    );
  } else if (trimmedEditedInput.length > Constants.numberOfMaximumCharactersForName) {
    throw RangeError(
      `The input value cannot exceed ${Constants.numberOfMaximumCharactersForName} characters`
    );
  }
  return trimmedEditedInput;
}

export function handleError(err: any) {
  if (err.response) {
    console.log(err.response.data);
    console.log(err.response.status);
    console.log(err.response.headers);
  } else if (err.request) {
    console.log(err.request);
  } else {
    console.log('Error:', err.message);
  }
}

export function getErrorMessage(err: unknown): string {
  if (typeof err === 'string') {
    return err;
  }
  if (err instanceof Error) {
    return err.message;
  }
  console.error(err);
  return 'Internal error';
}

export const formatDate = (date: Date, yearMonthsDays?: boolean) => {
  const d = DateTime.fromISO(date.toString(), { zone: 'America/New_York' });
  if (yearMonthsDays) {
    return d.toFormat('MM-dd-yyyy');
  }
  return d.toFormat('yyyy LLL dd, HH:mm');
};

export function capitalizeFirstLetter(status: string) {
  return status.charAt(0).toUpperCase() + status.slice(1);
}
