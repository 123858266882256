import { FormEvent, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import useApi from '../../api';
import AssignmentGroupDropdown from '../../components/AssignmentGroupDropdown/AssignmentGroupDropdown';
import ListingCards from '../../components/ListingCards/ListingCards';
import CardsPagination from '../../components/CardsPagination/CardsPagination';
import { getErrorMessage, trimEditedInput } from '../../helper';
import { AssignmentGroup } from '../../models/AssignmentGroup';
import { PaginationInfo } from '../../models/PaginationInfo';

type ChannelCreateFormProps = {
  isCardModalDisplayed: boolean;
  onClose(): void;
};

type ChannelCreateFormData = {
  name: string;
  assignmentGroup: AssignmentGroup;
};

function ChannelCreateForm(props: ChannelCreateFormProps) {
  const { isCardModalDisplayed, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  // Form fields
  const [name, setName] = useState<string>('');
  const [assignmentGroup, setAssignmentGroup] = useState<AssignmentGroup | undefined>(undefined);

  const mutation = useMutation(
    (input: ChannelCreateFormData) => {
      const trimmedName = trimEditedInput(input.name);
      setName(trimmedName);
      setAssignmentGroup(input.assignmentGroup);

      return api.createChannel(trimmedName, input.assignmentGroup.id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['channels']);
        resetAndClose();
      },
    }
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!assignmentGroup) {
      throw Error('No assignment group selected');
    }
    mutation.mutate({ name, assignmentGroup });
  };

  const resetAndClose = () => {
    setName('');
    setAssignmentGroup(undefined);
    mutation.reset();
    onClose();
  };

  return (
    <Modal show={isCardModalDisplayed} onHide={resetAndClose} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Create Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Something went wrong...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Assignment Group</Form.Label>
            <AssignmentGroupDropdown value={assignmentGroup} onChange={setAssignmentGroup} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Save
          </Button>
          <Button variant="secondary" onClick={resetAndClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function Channels() {
  const api = useApi();

  const [isCardModalDisplayed, setisCardModalDisplayed] = useState<boolean>(false);
  const [cursor, setCursor] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationInfo>({
    pageSize: 10,
    next: null,
    previous: null,
  });

  const {
    data: channelsPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(['channels', cursor], () => api.getChannels(cursor), {
    onSuccess: (data) => setPagination(data.pagination),
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <div>
        <ListingCards
          cards={channelsPage.channels.map((channel) => ({
            id: channel.id,
            title: channel.name,
            link: `/channels/${channel.id}`,
          }))}
        />
        <div>
          <Button variant="success" onClick={() => setisCardModalDisplayed(true)}>
            Create a Channel
          </Button>
        </div>
        <CardsPagination
          pagination={pagination}
          onPageChange={(newCursor) => setCursor(newCursor)}
        />
        <ChannelCreateForm
          isCardModalDisplayed={isCardModalDisplayed}
          onClose={() => setisCardModalDisplayed(false)}
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return <div>Failed to load channels: {error.message}</div>;
  }

  return <div>Failed to load channels</div>;
}
