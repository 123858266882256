import { Paper, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useApi from '../../api';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../helper';
import { capitalizeFirstLetter } from '../../helper';

export default function HomePage() {
  const api = useApi();
  const navigate = useNavigate();

  const {
    data: homeDetailPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(['outageNotifications'], async () => {
    const outageNotificationsList = await api.getOutageNotifications();

    const filteredOutageNotification = outageNotificationsList.outageNotifications
      .filter((outageNotification) => outageNotification.status === 'active')
      .slice(0, 5);

    return { filteredOutageNotification };
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <Paper
        sx={{
          p: 2,
          margin: 'auto',
          marginTop: '1rem',
          maxWidth: 900,
          flexGrow: 1,
          borderRadius: '0.3rem',
          background:
            'linear-gradient(45deg, rgba(29, 236, 197, 0.5), rgba(91, 14, 214, 0.5) 100%)',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography gutterBottom variant="h5" component="h6">
              Welcome To Ops Bot
            </Typography>
            {homeDetailPage.filteredOutageNotification &&
            homeDetailPage.filteredOutageNotification.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      sx={{
                        '& th': {
                          fontWeight: 'bold',
                          backgroundColor: 'black',
                          color: 'white',
                        },
                      }}
                    >
                      <TableCell>Title</TableCell>
                      <TableCell>Type of Issue</TableCell>
                      <TableCell>Impact Type</TableCell>
                      <TableCell>Platform</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Date Created</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {homeDetailPage.filteredOutageNotification.map((row) => (
                      <TableRow
                        hover
                        onClick={() => navigate(`/outageNotifications/${row.id}`)}
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.titleText}
                        </TableCell>
                        <TableCell>{row.typeOfIssue}</TableCell>
                        <TableCell>{row.impactType}</TableCell>
                        <TableCell>{row.impactedToolName}</TableCell>
                        <TableCell>{capitalizeFirstLetter(row.status)}</TableCell>
                        <TableCell>{formatDate(row.createdAt)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="subtitle2" gutterBottom>
                There are currently no active outage notifications.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  if (error instanceof Error) {
    return <div>Failed to load outage notifications: {error.message}</div>;
  }
  return <div>Failed to load outage notifications</div>;
}
