import { FormEvent } from 'react';
import useApi from '../../api';
import { Alert, Button, Form, Modal, Row } from 'react-bootstrap';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { getErrorMessage, trimEditedInput } from '../../helper';
import { useState } from 'react';
import { PaginationInfo } from '../../models/PaginationInfo';
import ListingCards from '../../components/ListingCards/ListingCards';
import CardsPagination from '../../components/CardsPagination/CardsPagination';

type YammerGroupAddFormProps = {
  isCardModalDisplayed: boolean;
  onClose(): void;
};

type YammerGroupAddFormData = {
  name: string;
};

function YammerGroupAddForm(props: YammerGroupAddFormProps) {
  const { isCardModalDisplayed, onClose } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  const [name, setName] = useState<string>('');

  const [error, setError] = useState<string>('');

  const mutation = useMutation(
    (input: YammerGroupAddFormData) => {
      const trimmedName = trimEditedInput(name);
      return api.createYammerGroup(trimmedName);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['yammerGroups']);
        resetAndClose();
      },
      onError: (e: Error) => {
        setError(e.message);
      },
    }
  );

  const resetAndClose = () => {
    setName('');
    setError('');
    mutation.reset();
    onClose();
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (name === '') {
      setError('Must include name before submission');
    }

    mutation.mutate({
      name,
    });
  };

  return (
    <Modal show={isCardModalDisplayed} onHide={resetAndClose} backdrop="static" keyboard={false}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Add Yammer Community</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mutation.isLoading ? <Alert variant="primary">Saving...</Alert> : null}
          {error !== '' && mutation.isError ? (
            <Alert variant="danger">
              <Alert.Heading>Something went wrong...</Alert.Heading>
              <p>{getErrorMessage(mutation.error)}</p>
            </Alert>
          ) : null}
          <Row>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Yammer Community Name</Form.Label>
              <Form.Control
                className="w-50"
                type="text"
                value={name}
                autoFocus
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Save
          </Button>
          <Button variant="secondary" onClick={resetAndClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default function YammerGroups() {
  const api = useApi();

  const [isCardModalDisplayed, setIsCardModalDisplayed] = useState<boolean>(false);
  const [cursor, setCursor] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationInfo>({
    pageSize: 10,
    next: null,
    previous: null,
  });

  const {
    data: yammerGroupsPage,
    isLoading,
    isSuccess,
    error,
  } = useQuery(
    ['yammerGroups', cursor],
    () => {
      return api.getYammerGroups(cursor);
    },
    {
      onSuccess: (data) => setPagination(data.pagination),
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    return (
      <div>
        <ListingCards
          cards={yammerGroupsPage.yammerGroups.map((yammerGroup) => ({
            id: yammerGroup.id,
            title: yammerGroup.name,
            link: `/yammerGroups/${yammerGroup.id}`,
          }))}
        />
        <div>
          <Button variant="success" onClick={() => setIsCardModalDisplayed(true)}>
            Create a Yammer Group
          </Button>
        </div>
        <CardsPagination
          pagination={pagination}
          onPageChange={(newCursor) => setCursor(newCursor)}
        />
        <YammerGroupAddForm
          isCardModalDisplayed={isCardModalDisplayed}
          onClose={() => setIsCardModalDisplayed(false)}
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return <div>Failed to load yammer groups: {error.message}</div>;
  }

  return <div>Failed to load yammer groups</div>;
}
